.about-container img {
    width:40%;
}

.about-container {
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1rem;
    margin-bottom: 40px;
    /* background-color: red; */
    width: 100%;
}

.about-text-container {
    max-width: 650px;
}

.about-container p {
    line-height: 1.3;
}

.p-margin-bottom {
    margin-bottom: 10px;
}

.about-container h3 {
    font-size: 2rem;
    margin-bottom: 30px;
}

.about-container h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

#second-row.about-container {
    gap: 10%;
    align-items: flex-start;
}

#second-row .about-text-container{
    max-width: 450px;
}

.about-container a {
    text-decoration: none;
    font-weight: 700;
}

@media screen and (max-width: 960px) {
    .about-container img {
        width: 100%;
    }
    .about-container {
        flex-direction: column;
    }

    #second-row.about-container {
        flex-direction: column-reverse;
        align-items: center;
        gap: 40px;
    }

    #second-row .about-text-container{
        max-width: 650px;
    }
}