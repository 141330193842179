.hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    color: #343434;
    /* margin-top: 50px; */
    /* margin-bottom: 150px; */
}

.hero-container img {
    width: 30%;
}

.top-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.5rem;
    text-align: center;
}

.bottom-text {
    margin-top: 50px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
}

.bottom-text p {
    max-width: 250px;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    color: #7e7e7e;
    line-height: 1.5;
}

.top-text h1 {
    font-size: 4.2rem;
}

@media screen and (max-width: 960px) {
    .hero-container {
        flex-direction: column;
        padding: 0 40px;
    }

    .hero-container img {
        width: 40%;
    }

    .top-text {
        display:flex;
        justify-content: center;
        flex-direction: column;
    }
    .top-text h1 {
        font-size: 3rem;
    }
    .top-text h3 {
        font-size: 1rem;
    }
    .bottom-text {
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 40px;
    }
    .bottom-text p {
        font-size: 1rem;
        max-width: 70%;
    }
}

@media screen and (max-width: 760px) {
    .top-text h1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .bottom-text p {
        max-width: 90%;
    }
    .hero-container img {
        width: 50%;
    }
}

@media screen and (max-width: 390px) {
    .top-text h1 {
        font-size: 1.8rem;
        line-height: 1.2;
        letter-spacing: 0.2rem;
    }
    .top-text h3 {
        font-size: 0.7rem;
        letter-spacing: 0.1rem;
    }
}

/* @media screen and (max-width: 365px) {
    .top-text h1 {
        font-size: 1.6rem;
    }
} */

/* @media screen and (max-width: 500px) {
    .bottom-text p {
        max-width: 70%;
    }
} */

/* @media screen and (max-width: 630px) {
    .top-text {
        padding: 0 40px;
    }
    .top-text h1 {
        font-size: 3rem;
    }
    .top-text h3 {
        font-size: 1rem;
    }
    .bottom-text {
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 30px;
    }
    .bottom-text p {
        font-size: 1rem;
        max-width: 70%;
    }
}

@media screen and (max-width: 480px) {
    .top-text h1 {
        font-size: 2rem;
        display: flex;

    }
    .top-text h3 {
        font-size: 0.7rem;
        text-align: initial;
    }
    .bottom-text {
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 30px;
    }
    .bottom-text p {
        font-size: 1rem;
        max-width: 70%;
    }
} */