section {
    max-width: 10000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 40px 0 40px;
    color: #343434;
}

section h2{
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.3rem;
    font-size: 2rem;
    margin-bottom: 50px;
}

img {
    width: 100%;
}