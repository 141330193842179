.navbar {
    padding: 0 80px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    /* max-width: 1500px; */
    /* background-color: red; */
    background: rgb(255, 255, 255, 0.5);
}

.navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
}

.navbar-logo {
    color: #343434;
    /* justify-self: start; */
    /* margin-left: 20px; */
    /* cursor: pointer; */
    text-decoration: none;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
}

/* .navbar-logo:hover {
    color: #5d5d5d;
}

.navbar-logo .mint:hover {
    color: #d282fb;
} */

.nav-menu {
    /* background-color: yellow; */
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 50px;
    list-style: none;
    text-align: center;
    /* width: 60vw; */
    /* justify-content: end; */
    /* margin-right: 2rem; */
}

/* .nav-item {
    height: 500px;
    background-color: red;
} */

.nav-links {
    display: flex;
    align-items: center;
    letter-spacing: 0.1rem;
    /* padding: 0.5rem 1rem; */
    /* height: 100%; */
}

.nav-links a {
    color: #343434;
    text-decoration: none;
}

.nav-links a:hover {
    color: #5d5d5d;
}

.nav-links:hover {
    border-bottom: 4px solid #02e0b7;
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.mint {
    color: #02e0b7;
}

.purple{
    color: #d282fb;
}

.fa-circle {
    margin-right: 10px;
}

.fa-bars, .fa-times {
    color: #343434;
}

/* .nav-links-mobile {
    display: none;
} */

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    /* .NavbarItems {
        position: relative;
    } */

    .navbar {
        padding: 0px 35px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        /* background-color: red; */
    }

    .nav-links:hover {
        border-bottom: none;
        transition: none;
    }

    .menu-icon {
        display: block;
        /* position: absolute; */
        /* background-color: green; */
        /* top: 0;
        right: 0;
        transform: translate(-100%, 60%);*/
        font-size: 1.8rem;
        cursor: pointer;
        justify-content: end;
        /* z-index: 1000; */
    }

    /* .fa-times {
        color: #fff;
        font-size: 2rem;
    } */
}