.quiz {
    /* declares some colours */
    --color-primary: #02e0b7;
    --color-primary-dark: #00bb98;
    --color-secondary: #252c6a;
    --color-error: #cc3333;
    --color-success: #4bb544;
    --border-radius: 10px;

    margin: 0;
    /* height: 90vh; */
    padding: 50px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(
    to bottom,
    #02e0b7,
    #d282fb
    );

    border-radius: 10px;

    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.05rem;
    /* background: url(./trainBg.jpg);
    background-size: cover; */
}

form {
    /* width: 400px; */
    max-width: 500px;
    max-height: 800px;
    background-color: white;
    /* one unit of base font size, so 1rem = 18px */
    margin: 1rem;
    padding: 4rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius);
}

#introduction p {
    margin-bottom: 10px;
    line-height: 1.3;
}

h1{
    margin-top: 0px;
    margin-bottom: 2rem;
    text-align:center;
}

h3{
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.surveyQuestion {
    display: none;
}

input {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
}

#userName {
    display: block;
    width: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: 1px solid #dddddd;
    outline:none;
    background: #eeeeee;
    /* transition: background 0.2s, border-color 0.2s; */
}

#userName:focus {
    border-color: var(--color-primary);
    background: white;
}

.invalid {
    background-color: #ffdddd;
}

#error_message_container {
    text-align: center; 
    margin-bottom: 1rem;
    color: red;
    font-size: 0.8rem;
}

.buttonsContainer {
    display:flex;
    justify-content: right;
    gap: 1rem;
}

button {
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    border: none;
    color: white;
    width: 95px;
    border-radius: var(--border-radius);
}

#prevBtn {
    background-color: rgb(191, 191, 191);
}

#prevBtn:hover {
    background-color: rgb(222, 222, 222);
    cursor: pointer;
}


#nextBtn, #startBtn {
    background-color: #02e0b7;
}

#prevBtn, #nextBtn {
    display: none;
}

#prevBtn, #nextBtn, #startBtn {
    font-weight: 700;
}

#nextBtn:hover, #startBtn {
    background-color: rgb(0, 241, 197);
    cursor: pointer;
}

#introduction img {
    margin-top: 1rem;
    width: 100%;
    border-radius: var(--border-radius);
}

@media screen and (max-width: 620px) {
    .quiz {
        background-image: none;
        height: 100%;
    }
    form {
        margin: 0;
        padding: 2rem;
    }
}