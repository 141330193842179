.project {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
}

.project img{
    width: 50%;
}

.project-text-container {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1rem;
    max-width: 500px;
}

.project-text-container h3 {
    margin-bottom: 50px;
    font-size: 1.5rem;
}

.project-text-container p {
    line-height: 1.5;
    font-size: 1rem;
    text-align: justify;
}

.project-text-container a {
    text-decoration: none;
    font-weight: 700;
}

a:hover {
    cursor: pointer;
    color: #7e7e7e;
}

@media screen and (max-width: 960px) {
    .project {
        flex-direction: column;
        gap: 10px;
    }
    .project img {
        width: 100%;
    }
    .project-text-container h3 {
        margin-bottom: 20px;
        font-size: 1.5rem;
    }
}